export function downloadFile(file: any, filename: string) {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
}

export function getFilenameFromHeader(headers: Headers, defaultFilename: string) {
    const contentDisposition = headers.get('content-disposition');
    if (contentDisposition) {
        const foundFilename = contentDisposition.split(';').map(part => part.trim()).filter(part => part.startsWith('filename=')).pop();
        if (foundFilename) {
            return foundFilename.replace(/filename=|['"]/g, '');
        }
    }
    return defaultFilename;
}

export function downloadFileFromResponse(data: { blob: Blob; headers: Headers }, defaultFilename: string) {
    const filename = getFilenameFromHeader(data.headers, defaultFilename);
    downloadFile(data.blob, filename);
}
