import {getMissingJourneyInfo, Journey} from "@/models/JourneyModel";
import {EMPTY_GUID} from "@/utils/GuidUtil";
import {getTemplate} from "@/services/TemplateService";
import {getAppInstance} from "@/services/AppInstanceService";
import {getMissingTemplateInfo} from "@/models/JourneyTemplateModel";
import {getMissingTabInfo, JourneyTab} from "@/models/JourneyTabModel";
import {store} from "@/store/Store";
import {getJourneySet} from "@/services/JourneySetService";

export interface JourneySet {
    id: string;
    title: string;
    imageId: string;
    imageData: string;
    published: boolean;
    publishedOn?: string;
    templateId: string;
    templateName: string;
    enableCustomContent: boolean;
    keyword: string;
    displayOrder: number;
    journeys: Journey[];
    journeySetTypeId: string;
    appInstanceId: string;
    updateAvailable: boolean;
    legacy: boolean;
    isPwaDefault: boolean;
    isSecureDefault: boolean;
    isAppDefault: boolean;
}

function getMissingJourneySetInfo(journeySet: JourneySet, language: any): string {
    let message = '';
    if (!journeySet.title?.trim()) {
        message += `<li>${ language.journeySetTitle }</li>`;
    }
    if (!journeySet.keyword?.trim()) {
        message += `<li>${ language.journeySetKeyword }</li>`;
    }
    if (!journeySet.imageId || journeySet.imageId === EMPTY_GUID) {
        message += `<li>${ language.journeySetImage }</li>`;
    }
    if (!journeySet.journeySetTypeId || journeySet.journeySetTypeId === EMPTY_GUID) {
        message += `<li>${ language.journeySetCategory }</li>`;
    }
    if (journeySet.journeys.length === 0) {
        message += `<li>${ language.journeys }</li>`;
    }
    return message;
}

export async function getMissingInfo(id: string, download: boolean, language: any): Promise<string> {
    const journeySet = await getJourneySet(id);
    let message = '';
    const template = await getTemplate(journeySet.templateId);
    const appInstance = await getAppInstance(store.getters['app/selectedAppInstanceId']);
    const needsExtraInfo = template.tabsCount === 4;
    journeySet.journeys.map((journey: Journey) => {
        message += getMissingJourneyInfo(journey, needsExtraInfo, appInstance, language);
    });

    message += getMissingJourneySetInfo(journeySet, language);
    message += getMissingTemplateInfo(template, language);

    template.tabs.map((tab: JourneyTab, index) => {
        message += getMissingTabInfo(tab, index, language);
    });

    if (message) {
        message = download ? 
            (language.missingInfoDownloadWarning + '<ul>' + message + '</ul>' + language.missingInfoDownloadWarningQuestion) : 
            (language.missingInfoPublishWarning + '<ul>' + message + '</ul>' + language.missingInfoPublishWarningQuestion);
    }
    
    return message;
}