import {JourneyTab} from "@/models/JourneyTabModel";

export interface JourneyTemplate {
    id: string;
    name: string;
    complete: boolean;
    createdDate: string;
    lastModified?: string;
    tabsCount: number;
    tabs: JourneyTab[];
    appInstanceId: string;
    locked: boolean;
    enableCustomContent: boolean;
}

export function getMissingTemplateInfo(template: JourneyTemplate, language: any): string {
    let message = '';
    if (!template.name?.trim()) {
        message += `<li>${ language.templateName }</li>`;
    }
    if (!template.tabsCount || template.tabsCount === 0) {
        message += `<li>${ language.templateTabs }</li>`;
    }
    return message;
}