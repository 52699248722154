import {store} from "@/store/Store";

export function getTranslatedNumber(number: number) {
    const { numbers } = store.getters['app/language'];
    const numberArray = [
        numbers.zero,
        numbers.one,
        numbers.two,
        numbers.three,
        numbers.four,
        numbers.five,
        numbers.six,
        numbers.seven,
        numbers.eight,
        numbers.nine,
        numbers.ten,
        numbers.eleven,
        numbers.twelve,
        numbers.thirteen,
        numbers.fourteen,
        numbers.fifteen,
        numbers.sixteen,
        numbers.seventeen,
        numbers.eighteen,
        numbers.nineteen,
        numbers.twenty
    ];
    return numberArray[number];
}